
























































































































































// Core
import {Component, Prop, Mixins} from 'vue-property-decorator'
import moment from 'moment'
import http from '@/http'

// Mixins
import GetStoreMixin from '@/mixins/GetStore'

// Store
import {documentsNamespace} from '@store/documents'
import {userNamespace} from '@store/user'
import {worksNamespace} from '@store/works'

// Types
import {UserActionTypes} from '@store/user/Types'
import {WorksActionTypes} from '@store/works/Types'

// Interfaces
import {IDocumentTypes} from '@store/documents/Interface'
import {IWork} from '@store/works/Interface'

interface IFormAddFact {
  projectId: number | null
  workId: number | null
  startDate: string
  expirationDate: string
  name: string
  amount: number | null
  fileList: any
}

@Component
export default class FormAddFact extends Mixins(GetStoreMixin) {
  @Prop({default: () => null})
  private work!: IWork

  @documentsNamespace.State('documentTypes')
  private documentType!: IDocumentTypes

  @worksNamespace.Action(WorksActionTypes.A_ADD_FACT)
  private addFact!: (fact) => Promise<any>

  @worksNamespace.Action(WorksActionTypes.A_ADD_SEND_FORMATION)
  private sendToFormation!: (fact) => Promise<any>

  @userNamespace.Action(UserActionTypes.A_ADD_TASK)
  private addTask!: (FormAddFact: IFormAddFact) => Promise<any>

  private workList: IWork[] = []
  private isExpectation: boolean = false
  // private SelectedWork: IWork |  null = null;
  private rules: any = {
    workId: [{required: true, message: 'Обязательное поле', trigger: 'change'}],
    startDate: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
    expirationDate: [{required: true, message: 'Обязательное поле', trigger: 'blur'}],
    amount: [
      {required: true, message: 'Обязательное поле', trigger: 'blur'},
      {type: 'number', message: 'Только число'},
      // { type: 'float', message: 'Только число', trigger: 'change' },
    ],
  }

  private formAddFact: IFormAddFact = {
    projectId: null,
    workId: null,
    expirationDate: moment(new Date()).format('YYYY-MM-DD h:mm:ss'),
    startDate: '',
    name: '',
    amount: null,
    fileList: [],
  }

  get leftovers() {
    if (this.work !== null) {
      return Math.round((this.work.volume! - (this.work.volume! * this.work.completePercent!) / 100) * 1000) / 1000
    }

    const delta = (this.selectedWork!.volume! * this.selectedWork!.completePercent!) / 100

    return Math.round(this.selectedWork!.volume! - delta * 1000) / 1000
  }

  get selectedWork() {
    this.workList.find(work => work.id === this.formAddFact.workId)
    return this.workList.find(work => work.id === this.formAddFact.workId) || null
  }

  get files() {
    return this.formAddFact.fileList.map(file => file.file)
  }

  private changeFileList(_file, fileList) {
    this.formAddFact.fileList = fileList.map(item => ({file: item}))
  }

  private amountChange() {
    const eventAmount: any = event
    if (eventAmount) {
      const value = eventAmount.target.value || null
      const isFloat = n => !!(n % 1)

      const filterInt = num => {
        if (/^(\-|\+)?([0-9]+|Infinity)$/.test(num)) {
          return Number(num)
        }
        return NaN
      }

      if (!isNaN(filterInt(value))) {
        this.formAddFact.amount = Number(eventAmount.target.value)
      } else if (isFloat(value)) {
        this.formAddFact.amount = parseFloat(eventAmount.target.value)
      }
    }
  }

  private changeWork() {
    this.formAddFact.name = this.selectedWork ? this.selectedWork.name : ''
  }

  private sendFact(formName) {
    const ref: any = this.$refs[formName]
    ref.validate(valid => {
      if (valid) {
        this.isExpectation = true
        this.addFact(this.formAddFact)
          .then(() => {
            this.$emit('close-dialog')
            this.$successNotify()
          })
          .catch(error => this.$errorNotify(error?.response?.data?.message))
          .finally(() => this.isExpectation = false)
      } else {
        return false
      }
    })
  }

  private sendID(formName) {
    const ref: any = this.$refs[formName]
    ref.validate(valid => {
      if (valid) {
        this.isExpectation = true
        this.sendToFormation(this.formAddFact)
          .then(() => {
            this.$emit('close-dialog')
            this.$successNotify()
          })
          .catch(error => this.$errorNotify(error?.response?.data?.message))
          .finally(() => this.isExpectation = false)
      } else {
        return false
      }
    })
  }

  private deleteDocument(uid) {
    const index = this.formAddFact.fileList.findIndex(file => file.file.uid === uid)
    this.formAddFact.fileList.splice(index, 1)
  }

  private async filterMethod(filterValue, _allOptions) {
    const {data} = await http.get('/works', {params: {search: filterValue, project_id: this.selectedProject}})
    this.workList = data.data
    return data.data.filter(option => option.name.includes(filterValue))
  }

  protected mounted() {
    http.get('/works', {params: {project_id: this.selectedProject}}).then(response => {
      this.workList = response.data.data
    })
    if (this.work) {
      this.formAddFact.name = this.work.name
      this.formAddFact.workId = this.work.id
    }
    this.formAddFact.projectId = this.selectedProject
  }
}
